import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

export default function AboutUsPage() {
  return (
    <Layout>
      <Seo
        title="Cheap Storage Units Near Colorado Springs - Shipping Container Storage Units"
        description="Carson Bluffs Self Storage offers cheap storage units near Colorado Springs. We also have shipping container storage units for commercial storage needs."
      />
      <div className="container mx-auto px-4 py-6">
        <hr />
      </div>
      <section className="py-12 text-center">
        <div className="container mx-auto px-4">
          <div className="mb-6">
            <h2 className="font-medium mb-2 text-4xl text-gray-700">
              How it works
            </h2>
          </div>
          <section className="bg-gray-50 text-gray-500">
            <section className="bg-gray-50 text-gray-500">
              <div className="container mx-auto px-4 relative  lg:text-left text-center">
                <div className="flex flex-wrap -mx-4  items-center">
                  <div className="p-4 w-full lg:w-6/12">
                    <img
                      src="https://images.unsplash.com/photo-1600725935160-f67ee4f6084a?ixid=MnwyMDkyMnwwfDF8c2VhcmNofDZ8fG1vdmluZ3xlbnwwfHx8fDE2MjUyNzU5OTA&ixlib=rb-1.2.1q=85&fm=jpg&crop=faces&cs=srgb&w=1440&fit=max"
                      className="mx-auto shadow-lg"
                      alt="..."
                      width="540"
                      height="360"
                    />
                  </div>
                  <div className="p-4 w-full lg:w-6/12">
                    <div className="py-4">
                      <h3 className="font-bold mb-1 text-gray-800 text-xl">
                        Testimonials
                      </h3>
                      <div className="flex flex-col items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          width="1em"
                          height="1em"
                          className="text-7xl"
                        >
                          <g>
                            <path fill="none" d="M0 0H24V24H0z"></path>
                            <path d="M21 3c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H6.455L2 22.5V4c0-.552.448-1 1-1h18zm-1 2H4v13.385L5.763 17H20V5zm-9.485 2.412l.447.688c-1.668.903-1.639 2.352-1.639 2.664.155-.02.318-.024.48-.009.902.084 1.613.825 1.613 1.745 0 .966-.784 1.75-1.75 1.75-.537 0-1.05-.245-1.374-.59-.515-.546-.792-1.16-.792-2.155 0-1.75 1.228-3.318 3.015-4.093zm5 0l.447.688c-1.668.903-1.639 2.352-1.639 2.664.155-.02.318-.024.48-.009.902.084 1.613.825 1.613 1.745 0 .966-.784 1.75-1.75 1.75-.537 0-1.05-.245-1.374-.59-.515-.546-.792-1.16-.792-2.155 0-1.75 1.228-3.318 3.015-4.093z"></path>
                          </g>
                        </svg>
                        <p>
                          &ldquo;Good people. Small mom-and-pop facility. They
                          are open on weekends, which helps.&rdquo;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
      <section className="bg-red-600 py-16 text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4  items-center">
            <div className="px-4 py-2 w-full lg:w-7/12">
              <h2 className="font-medium mb-4 text-4xl">
                Join Our Amazing Community
              </h2>
              <p>
                {' '}
                Get started today. Call or message us now to find the perfect
                storage unit for your situation. We make it easy to handle your
                storage needs.
              </p>
            </div>
            <div className="px-4 py-2 w-full lg:ml-auto lg:w-auto">
              <Link
                to="/contact"
                className="border hover:bg-white hover:text-red-600 inline-block px-6 py-2 rounded-full text-center text-white"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="container mx-auto px-4">
          <div className="mb-6 text-center">
            <h2 className="font-medium mb-2 text-4xl text-gray-700">
              Most Popular Units
            </h2>
          </div>
          <div className="container mx-auto px-4">
            <div className="-mx-4 flex flex-wrap justify-center">
              <div className="p-4 w-full md:w-6/12 lg:w-4/12">
                <a
                  href="tel:+17193820999"
                  className="block group pb-6 pt-72 px-6 relative text-white"
                >
                  {' '}
                  <StaticImage
                    src="../images/5x10-unit-illustration.png"
                    placeholder="blurred"
                    className="absolute h-full left-0 object-contain top-0 w-full rounded shadow-xl"
                    alt="Location image"
                    loading="lazy"
                    width="320"
                    height="400"
                    quality={80}
                  />
                  <div className="relative">
                    <h3 className="font-medium mb-1 text-gray-900 text-xl">
                      5x10 Unit
                    </h3>
                    <p className="mb-4"></p>
                    <div className="bg-white group-hover:bg-red-600 group-hover:text-white inline-block pl-4 pr-6 py-2 rounded-full text-center text-gray-800">
                      <span className="align-middle">Call Now</span>
                    </div>
                  </div>{' '}
                </a>
              </div>
              <div className="p-4 w-full md:w-6/12 lg:w-4/12">
                <a
                  href="tel:+17193820999"
                  className="block group pb-6 pt-72 px-6 relative text-white"
                >
                  {' '}
                  <StaticImage
                    src="../images/8x19-unit-illustration.png"
                    placeholder="tracedSVG"
                    className="absolute h-full left-0 object-center object-cover top-0 w-full"
                    alt="Location image"
                    loading="lazy"
                    width="320"
                    height="400"
                    quality={80}
                  />
                  <div className="relative">
                    <h3 className="font-medium mb-1 text-black text-xl">
                      8x10 Unit
                    </h3>
                    <p className="mb-4">784+ listing</p>
                    <div className="bg-white group-hover:bg-red-600 group-hover:text-white inline-block pl-4 pr-6 py-2 rounded-full text-center text-gray-800">
                      <span className="align-middle">Call Now</span>
                    </div>
                  </div>{' '}
                </a>
              </div>
              <div className="p-4 w-full md:w-6/12 lg:w-4/12">
                <a
                  href="tel:+17193820999"
                  className="block group pb-6 pt-72 px-6 relative text-white"
                >
                  {' '}
                  <StaticImage
                    src="../images/10x20-unit-illustration.png"
                    placeholder="blurred"
                    className="absolute h-full left-0 object-center object-cover top-0 w-full"
                    alt="Location image"
                    loading="lazy"
                    width="320"
                    height="400"
                    quality={80}
                  />
                  <div className="relative">
                    <h3 className="font-medium mb-1 text-black text-xl">
                      10x20
                    </h3>
                    <p className="mb-4 text-black">
                      Enough for the entire family
                    </p>
                    <div className="bg-white group-hover:bg-red-600 group-hover:text-white inline-block pl-4 pr-6 py-2 rounded-full text-center text-gray-800">
                      <span className="align-middle">Call Now</span>
                    </div>
                  </div>{' '}
                </a>
              </div>
            </div>
          </div>
          <div className="py-4 text-center">
            <Link
              to="/"
              className="bg-red-600 hover:bg-red-700 inline-block px-6 py-2 rounded-full text-center text-white"
            >
              View More
            </Link>
          </div>
        </div>
      </section>
      <div className="container mx-auto px-4 py-6">
        <hr />
      </div>
      <section className="bg-gray-50 py-24 text-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4  items-center">
            <div className="mx-auto px-4 w-full lg:w-7/12">
              <h2 className="font-medium mb-2 text-4xl text-gray-700">
                Enjoy A Clean Space
              </h2>
              <p className="mb-6">
                {' '}
                Clear up extra room in your home, manage your business storage,
                and enjoy the extra space. Contact us today to secure your self
                storage space or a shipping container storage unit.
              </p>
              <Link
                to="/contact"
                className="bg-red-600 hover:bg-red-700 inline-block px-6 py-2 rounded-full text-center text-white"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
